import styled, { keyframes, css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import { device } from "../../../../../../../utils/breakpoints";
import {
  fadeIn,
  fadeOut,
  slideToRight,
} from "../../../../../../../utils/animations";
import background from "../../../../../../../assets/images/apps/AITVStream/background.png";
import headerImage from "../../../../../../../assets/images/apps/AITVStream/headerImage.png";
import {
  H2,
  H3,
  H6,
  TEXT_BODY_2,
} from "../../../../../../../assets/styles/typography";

const rectangleWidth = "8vw";
const rectangleHeight = "8vw";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #013cff;
`;

export const TransitionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(5, auto);
  z-index: 1000;
  pointer-events: none;

  .whiteBlock {
    animation: ${(props: PageProps) =>
      props.animation
        ? css`
            ${fadeIn} 300ms linear
          `
        : css`
            ${slideToRight} 300ms ease-in
          `};
    animation-fill-mode: forwards;
    animation-delay: ${(props: PageProps) => !props.animation && "0.7s"};

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(11),
    &:nth-child(14),
    &:nth-child(19) {
      animation-delay: ${(props: PageProps) => !props.animation && "0.1s"};
    }

    &:nth-child(0),
    &:nth-child(1),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(13),
    &:nth-child(16) {
      animation-delay: ${(props: PageProps) => !props.animation && "0.4s"};
    }
  }
`;

export const TransitionBlock = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  margin-left: auto;
  background: #ffffff;
  z-index: 1;
`;

export const Background = styled.div`
  background: url(${background});
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Grid = styled.div`
  @media ${device.desktop} {
    margin: 0 120px;
    display: grid;
    grid-template-columns: repeat(12, 110px);
    grid-column-gap: 32px;
    height: 100vh;
  } ;
`;

export const HeaderImage = styled.div`
  background: url(${headerImage});
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const TitleWrapper = styled.div`
  padding: 88px 140px 0 88px;
  color: #ffffff;
  z-index: 1;

  @media ${device.computer} {
    padding: 128px 140px 32px 120px;
  }

  @media ${device.desktop} {
    padding: 85px 200px 48px 120px;
  } ;
`;

export const Title = styled.div`
  margin: 0;
  margin-bottom: 16px;
  font-size: 40px;
  line-height: 42px;
  font-weight: 800;
  color: #ffffff;
  white-space: pre-line;

  @media ${device.computer} {
    ${H3}
  }

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const Subtitle = styled.div`
  ${H6}
  margin: 0;
  color: #ffffff;
  white-space: pre-line;
`;

export const ProjectPageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.desktop} {
    height: 100%;
    min-height: auto;
  }
`;

export const Banner = styled.div`
  position: relative;
  width: 70%;
  height: 36vh;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 100%;
  };

  @media ${device.computer} {
    width: 100%;
    height: 60vh;
  };

  @media ${device.desktop} {
    grid-column: 1 / 7;
    height 390px;
  };
`;

export const ContentWrapper = styled.div`
  flex: 1;
  width: calc(70% - ${rectangleWidth});
  padding: 0 140px 0 120px;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 100%;
    padding: 56px 178px 56px 88px;
  }

  @media ${device.computer} {
    height: 934px;
    padding: 128px 120px 120px 120px;
  }

  @media ${device.desktop} {
    grid-column: 1 / 10;
    height: calc(100vh - 390px);
    padding: 50px 0 0 0;
  } ;
`;

export const ContentTitle = styled.h2`
  width: 64%;
  margin: 52px 0 32px 0;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  color: #151515

  span:last-child {
    color: #013CFF;
    };
  };

  @media ${device.tablet} {
    width: 90%;
    margin-top: 0;
  };

  @media ${device.tabletLandscape} {
    margin-top: 0;
  };

  @media ${device.computer} {
    ${H3}
    margin: 0 0 48px 0;
    width: 80%;
  };

  @media ${device.desktop} {
    ${H2}
    margin: 0;
    width: auto;
  };
`;

export const RowWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  @media ${device.desktop} {
    flex-direction: row;

    &:first-of-type {
      margin-top: 48px;
    }
  } ;
`;

export const Description = styled.span`
  ${TEXT_BODY_2}
  flex: 1;
  margin: 0;
  margin-bottom: 16px;
  color: inherit;
  white-space: pre-line;
  opacity: 0;
  animation: ${fadeIn} 0.6s linear;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;

  @media ${device.computer} {
    width: 70%;
  }

  @media ${device.desktop} {
    width: auto;

    &:nth-of-type(2n) {
      margin-left: 64px;
    }
  }
`;

export const SideWrapper = styled.div`
  display: none;

  @media ${device.computer} {
    position: absolute;
    display: block;
    width: 51vw;
    background: #013cff;
    top: 60vh;
    right: 0;
    height: 934px;
  }

  @media ${device.desktop} {
    top: 390px;
    height: calc(100vh - 390px);
    grid-column: 7 / 12;
    margin-left: 32px;
  } ;
`;

const slideFromRightWithWidthChange = keyframes`
  0% {
    width: 0;
  };

  100% {
    width: 100%;
  };
`;

export const SideTransitionWrapper = styled.div`
  width: 0;
  height: 100%;
  margin-left: auto;
  animation: ${slideFromRightWithWidthChange} 0.6s ease-out;
  animation-fill-mode: forwards;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet}, ${device.tabletLandscape} {
    padding: 56px 0;
    animation-delay: 1s;
  }

  @media ${device.desktop} {
    height: calc(100vh - 390px);
    padding: 0;
  } ;
`;

export const SideImage = styled(Img)<{
  fluid: FluidObject | FluidObject[];
}>`
  width: 90%;
  margin-top: 10%;

  img {
    object-fit: contain !important;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 420px;
    margin-top: 0;
  }

  @media ${device.computer} {
    width: 70%;
    margin: 0 100px 0 0;
  } ;
`;
