import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  TransitionWrapper,
  TransitionBlock,
  TitleWrapper,
  Title,
  Subtitle,
  Grid,
  ContentTitle,
  Description,
  ProjectPageWrapper,
  Banner,
  Background,
  ContentWrapper,
  RowWrapper,
  SideWrapper,
  SideTransitionWrapper,
  SideImage,
  HeaderImage
} from "./style";
import { PageChange } from "../../../../../../common/Scroll";
import { PageThemeType } from "../../../../../../../utils/types";
import { fixConjunctions } from "../../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../../common/PageTitle";

const transitionBlocks = [...Array(20)].map((_, index) => (
  <TransitionBlock key={index} className="whiteBlock" />
));

interface AITVStreamProps {
  pageChange: PageChange;
  scrollPageThemeChange?: (theme?: PageThemeType) => void;
}

const AITVStream: React.FC<AITVStreamProps> = ({
  pageChange,
  scrollPageThemeChange,
}) => {
  const { t } = useTranslation("rnd");
  const { side } = useStaticQuery(imagesQuery);

  useEffect(() => {
    document.body.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      document.body.removeEventListener("scroll", handleScroll);
      scrollPageThemeChange && scrollPageThemeChange(undefined);
    };
  }, []);

  const handleScroll = () => {
    const page = document
      .getElementById("AITVWhiteWrapper")
      ?.getBoundingClientRect();
    const pageBlue = document
      .getElementById("AITVBlueWrapper")
      ?.getBoundingClientRect();
    const pageIndicators = document.getElementsByClassName("pageIndicator");
    const scrollPadding = pageIndicators[
      pageIndicators.length - 2
    ]?.getBoundingClientRect().top;

    if (
      pageBlue &&
      pageBlue.y < scrollPadding &&
      pageBlue.height + pageBlue.y > scrollPadding
    ) {
      scrollPageThemeChange && scrollPageThemeChange("blue");
    } else if (
      page &&
      page.y < scrollPadding &&
      page.height + page.y > scrollPadding
    ) {
      scrollPageThemeChange && scrollPageThemeChange("white");
    } else {
      scrollPageThemeChange && scrollPageThemeChange(undefined);
    }
  };

  return (
    <>
      <PageTitle subtitle={t(`AITVStream_title`)} />
      <PageWrapper>
        <Background />
        <TitleWrapper>
          <Title>{t(`AITVStream_title`)}</Title>
          <Subtitle
            dangerouslySetInnerHTML={{
              __html: fixConjunctions(t(`AITVStream_description`)),
            }}
          />
        </TitleWrapper>
      </PageWrapper>
      <ProjectPageWrapper animation={pageChange} id="AITVWhiteWrapper">
        <Grid>
          <Banner>
            <HeaderImage />
          </Banner>
          <ContentWrapper>
            <ContentTitle>
              <span>{t(`AITVStream_description1`)}</span>
              <span>{t(`AITVStream_description2`)}</span>
            </ContentTitle>
            <RowWrapper>
              <Description
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`AITVStream_paragraph1`)),
                }}
              />
              <Description
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`AITVStream_paragraph4`)),
                }}
              />
            </RowWrapper>
            <RowWrapper>
              <Description
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`AITVStream_paragraph3`)),
                }}
              />
              <Description
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`AITVStream_paragraph2`)),
                }}
              />
            </RowWrapper>
          </ContentWrapper>

          <SideWrapper id="AITVBlueWrapper">
            <SideTransitionWrapper>
              {side && <SideImage fluid={side.childImageSharp.fluid} />}
            </SideTransitionWrapper>
          </SideWrapper>
        </Grid>
      </ProjectPageWrapper>

      <TransitionWrapper animation={pageChange}>
        {transitionBlocks}
      </TransitionWrapper>
    </>
  );
};

const imagesQuery = graphql`
  {
    side: file(
      name: { eq: "sideImage" }
      relativeDirectory: { eq: "apps/AITVStream" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default AITVStream;
